import React from "react";
import { useLocale, useTranslations } from "next-intl";

import { externalPaths } from "pathnames";
import { usePlayer } from "modules/player";
import { useCart } from "modules/cart/cart.hooks";
import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { Button } from "ui/button";
import { ButtonLink } from "ui/button/button-link";
import { Text } from "ui/text";
import { KlubLogo } from "ui/klub-logo";

import { AbookIcon } from "../../abook-icon";
import css from "./buttons.module.scss";

export const ClubJoinButton = () => {
  const t = useTranslations();
  const locale = useLocale();

  return (
    <div className={css.clubButtonWrapper}>
      <ButtonLink color="club" className={css.clubButton} href={externalPaths.clubLandingPage[locale]}>
        <span>{t("product.price_box.cta.club_join")}</span>
        <KlubLogo version="empty" width="93" height="18" />
      </ButtonLink>
      <Text type="bodySmall" className={css.trial}>
        {t("product.price_box.cta.club_join_trial")}
      </Text>
    </div>
  );
};

export const AddToCartButton = ({
  audiobook,
  licenses,
  outlined,
}: {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  outlined?: boolean;
}) => {
  const t = useTranslations();
  const cart = useCart();

  const addToCart = () => {
    cart.addToCart(audiobook, licenses);
  };

  return (
    <Button className={css.addToCartButton} loading={cart.isLoading} onClick={addToCart} outlined={outlined}>
      {t("product.price_box.cta.buy")}
    </Button>
  );
};

export const PlayButton = ({
  audiobook,
  outlined,
  club,
  sample,
}: {
  audiobook: Audiobook;
  outlined?: boolean;
  club?: boolean;
  sample?: boolean;
}) => {
  const t = useTranslations();
  const player = usePlayer();

  const handlePlay = async () => {
    if (player.audiobook?.id !== audiobook.id) {
      player.load(audiobook.id, { autoPlay: true });
    } else {
      player.play();
    }
  };

  return (
    <Button className={css.playButton} outlined={outlined} onClick={handlePlay} color={club ? "club" : "blue"}>
      <AbookIcon icon="play-alt" width="24" height="24" />
      {t("product.price_box.cta.play")}{" "}
      {sample && t("product.sample", { sampleLength: audiobook.sample_duration_in_minutes })}
    </Button>
  );
};
